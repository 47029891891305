import React, { useRef, useLayoutEffect, useState } from 'react';
import Header from '../components/Header';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import YoutubeHelper from '../services/youtubeHelper';
import TableHelper from '../services/tableHelper';
import { useParams } from 'react-router-dom';

import type { stat } from '../services/statHelper';
import { responsiveCarousel } from '../services/mobileHelper';

export default function Post(): React.JSX.Element {
  let asc = true;

  const { season, fileName } = useParams();
  const postFile = require('../posts/' + season + '/' + fileName + '.json');
  let boxScore: stat[];
  if (postFile.match) {
    boxScore = require('../data/boxScores/' + fileName + '.json');
  }

  const [boxScoreTable, setBoxScoreTable] = useState(getBoxScoreTable('name'));

  const [fullScreen, setFullScreen] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState('');

  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [videoWidth, setVideoWidth] = useState(0);

  useLayoutEffect(() => {
    if (videoContainerRef.current) {
      setVideoWidth(videoContainerRef.current.offsetWidth);
    }
  }, []);

  function youtubeElement(): React.JSX.Element {
    const youtubeMarkup = YoutubeHelper.getEmbedHTML(
      postFile.video,
      videoWidth
    );
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: youtubeMarkup === null ? '' : youtubeMarkup
        }}
      ></div>
    );
  }

  function fullScreenButton(image: string): React.JSX.Element {
    return (
      <div
        className="cursor-pointer"
        onClick={() => {
          setFullScreen(!fullScreen);
          setFullScreenImage(image);
        }}
      >
        <svg height="50" width="50" version="1.1" viewBox="0 0 36 36">
          <rect width="100%" height="100%" fill="black" />
          <path
            fill="#DC2626"
            d="m 10,16 2,0 0,-4 4,0 0,-2 L 10,10 l 0,6 0,0 z"
          ></path>
          <path
            fill="#DC2626"
            d="m 20,10 0,2 4,0 0,4 2,0 L 26,10 l -6,0 0,0 z"
          ></path>
          <path
            fill="#DC2626"
            d="m 24,24 -4,0 0,2 L 26,26 l 0,-6 -2,0 0,4 0,0 z"
          ></path>
          <path
            fill="#DC2626"
            d="M 12,20 10,20 10,26 l 6,0 0,-2 -4,0 0,-4 0,0 z"
          ></path>
        </svg>
      </div>
    );
  }

  function getBoxScoreTable(sortField: string): React.JSX.Element | undefined {
    if (boxScore) {
      const players: React.JSX.Element[] = [];
      boxScore
        .sort(function (a, b) {
          //this first if else puts Team in the last row
          if (a.name === 'Team') {
            return 1;
          } else if (b.name === 'Team') {
            return -1;
          }
          return TableHelper.sortRows(a, b, sortField, asc);
        })
        .forEach((player) => {
          const textClass =
            player.name === 'Team'
              ? 'bg-white font-bold text-center border-2 border-black'
              : 'text-white border-2 border-red-600 text-center';
          players.push(
            <tr key={player.name} className="border-red-900 border-4">
              <td
                className={textClass + ' absolute w-36 -ml-36 -mt-0.5 bg-black'}
              >
                {player.name}
              </td>
              <td className={textClass}>{player.no}</td>
              <td className={textClass}>{player.fgMade2pt}</td>
              <td className={textClass}>{player.fgAtt2pt}</td>
              <td className={textClass}>{player.fg2pt.toFixed(2)}</td>
              <td className={textClass}>{player.fgMade3pt}</td>
              <td className={textClass}>{player.fgAtt3pt}</td>
              <td className={textClass}>{player.fg3pt.toFixed(2)}</td>
              <td className={textClass}>{player.fgMade}</td>
              <td className={textClass}>{player.fgAtt}</td>
              <td className={textClass}>{player.fg.toFixed(2)}</td>
              <td className={textClass}>{player.ftMade}</td>
              <td className={textClass}>{player.ftAtt}</td>
              <td className={textClass}>{player.ft.toFixed(2)}</td>
              <td className={textClass}>{player.offRebound}</td>
              <td className={textClass}>{player.defRebound}</td>
              <td className={textClass}>{player.reboundTot}</td>
              <td className={textClass}>{player.assist}</td>
              <td className={textClass}>{player.turnover}</td>
              <td className={textClass}>{player.block}</td>
              <td className={textClass}>{player.steal}</td>
              <td className={textClass}>{player.personalFoul}</td>
              <td className={textClass}>{player.points}</td>
              <td className={textClass}>{player.eff}</td>
            </tr>
          );
        });

      asc = !asc;

      return (
        <table className="ml-[140px]">
          <thead>
            <tr className="border-red-900 border-4">
              {/*use this character if you want to have non line breaking space: &nbsp;   */}
              <th
                className="text-white border-2 border-red-600 cursor-pointer absolute w-36 -ml-36 -mt-0.5 bg-black"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('name'));
                }}
              >
                Player
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('no'));
                }}
              >
                Jersey&nbsp;No
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('fgMade2pt'));
                }}
              >
                2FGM
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('fgAtt2pt'));
                }}
              >
                2FGA
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('fg2pt'));
                }}
              >
                2FG%
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('fgMade3pt'));
                }}
              >
                3FGM
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('fgAtt3pt'));
                }}
              >
                3FGA
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('fg3pt'));
                }}
              >
                3FG%
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('fgMade'));
                }}
              >
                FGM
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('fgAtt'));
                }}
              >
                FGA.
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('fg'));
                }}
              >
                FG%
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('ftMade'));
                }}
              >
                FTM
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('ftAtt'));
                }}
              >
                FTA
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('ft'));
                }}
              >
                FT%
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('offRebound'));
                }}
              >
                OREB
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('defRebound'));
                }}
              >
                DREB
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('reboundTot'));
                }}
              >
                REB
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('assist'));
                }}
              >
                AST
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('turnover'));
                }}
              >
                TO
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('block'));
                }}
              >
                BLK
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('steal'));
                }}
              >
                STL
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('personalFoul'));
                }}
              >
                PF
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('points'));
                }}
              >
                PTS
              </th>
              <th
                className="text-white border-2 border-red-600 cursor-pointer"
                onClick={() => {
                  setBoxScoreTable(getBoxScoreTable('eff'));
                }}
              >
                EFF
              </th>
            </tr>
          </thead>
          <tbody>{players}</tbody>
        </table>
      );
    }
  }

  function images(): React.JSX.Element | React.JSX.Element[] {
    const imageElements: React.JSX.Element[] = [];
    postFile.images.forEach((image: string) => {
      imageElements.push(
        <div key={image} className="relative flex justify-center">
          <div className="absolute top-0 right-0">
            {fullScreenButton(image)}
          </div>
          <img src={image} alt="lok" className="max-h-[32rem]" />
        </div>
      );
    });
    if (imageElements.length === 1) {
      return imageElements;
    } else {
      return (
        <Carousel responsive={responsiveCarousel} infinite={false}>
          {imageElements}
        </Carousel>
      );
    }
  }

  return (
    <div>
      <Header />
      <div className={fullScreen ? 'visible' : 'hidden'}>
        <div className="relative w-full">
          <div className="absolute top-0 right-0">
            {fullScreenButton(fullScreenImage)}
          </div>
          <img width="100%" src={fullScreenImage} alt="lok" />
        </div>
      </div>
      <div className={fullScreen ? 'hidden' : 'visible'}>
        <div className="flex justify-center">
          <div className="w-3/4">{images()}</div>
        </div>
      </div>

      <div className="flex text-white justify-center">
        <div className="md:w-2/4 w-full px-5">
          <h1 className="text-center m-2">
            <b>{postFile.title}</b>
          </h1>
          <p dangerouslySetInnerHTML={{ __html: postFile.text }}></p>
          <p className="m-2 text-right text-xs">posted on {postFile.date}</p>
        </div>
      </div>
      {postFile.match && (
        <>
          <h1 className="text-center text-white m-2">
            <b>BOX SCORE</b>
          </h1>
          <div className="flex justify-center my-5 ">
            <div className="md:w-3/4 w-full xl:flex xl:justify-center overflow-x-auto">
              {boxScoreTable}
            </div>
          </div>
        </>
      )}
      {postFile.video && (
        <>
          <h1 className="text-center text-white m-2">
            <b>FULL REPLAY</b>
          </h1>
          <div className="flex justify-center my-5">
            <div ref={videoContainerRef} className="md:w-2/4 w-full">
              {youtubeElement()}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
