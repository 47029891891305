import React from 'react';
import HeaderButton from './HeaderButton';

import { useNavigate } from 'react-router-dom';

export default function Header(): React.JSX.Element {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center">
      <div className="flex flex-row">
        <div className="cursor-pointer mx-4 mt-1.5 w-12">
          <img
            src="/lokLogo.jpeg"
            alt="lok logo"
            width="48"
            onClick={() => {
              navigate('/');
            }}
            onAuxClick={() => {
              window.open('/', '_blank');
            }}
          />
        </div>
        <div className="flex justify-center flex-row flex-wrap md:w-full w-3/4">
          <HeaderButton link="/roster" text="ROSTER" />
          <HeaderButton link="/schedule" text="SCHEDULE" />
          <HeaderButton link="/results" text="RESULTS" />
          <HeaderButton link="/stats" text="STATS" />
          <HeaderButton link="/post/2022-2023/2022-10-04" text="ABOUT" />
        </div>
      </div>
    </div>
  );
}
