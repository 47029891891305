import React, { useState } from 'react';
import Header from '../components/Header';
import schedule_2022_2023 from '../data/schedule/2022-2023/schedule.json';
import schedule_2023_2024 from '../data/schedule/2023-2024/schedule.json';
import Expandable from '../components/Expandable';

export default function Schedule(): React.JSX.Element {
  function scheduleTable(firstYear: number): React.JSX.Element {
    const games: React.JSX.Element[] = [];
    let schedule = schedule_2022_2023;
    if (firstYear === 2022) {
      schedule = schedule_2022_2023;
    } else if (firstYear === 2023) {
      schedule = schedule_2023_2024;
    } else {
      //todo years in the future
    }

    if (schedule.length === 0) {
      schedule = [
        {
          opponent: 'SCHEDULE',
          date: 'TO',
          time: 'BE',
          location: 'ANNOUNCED!',
          home: true
        }
      ];
    }

    schedule
      .sort((a, b) => {
        //from internet copied sort code, im so lazy i dont even write it
        // https://thewebdev.info/2022/01/23/how-to-sort-a-date-string-array-with-javascript/
        //dates need to be like this: 13.10.2022
        const newA = a.date.split('.').reverse().join('-');
        const newB = b.date.split('.').reverse().join('-');
        return +new Date(newA) - +new Date(newB);
      })
      .forEach((game) => {
        const court = game.home ? 'HOME' : 'AWAY';
        games.push(
          <tr className="border-red-900 border-4">
            <td className="text-white border-2 border-red-600 text-center">
              <p>{game.opponent}</p>
            </td>
            <td className="text-white border-2 border-red-600 text-center">
              <p>{court}</p>
            </td>
            <td className="text-white border-2 border-red-600 text-center">
              {game.date}
            </td>
            <td className="text-white border-2 border-red-600 text-center">
              {game.time}
            </td>
            <td className="text-white border-2 border-red-600 text-center">
              @{game.location}
            </td>
          </tr>
        );
      });

    return (
      <table className="w-2/4">
        <thead>
          <tr className="border-red-900 border-4">
            <th className="text-white border-2 border-red-600">OPPONENT</th>
            <th className="text-white border-2 border-red-600">COURT</th>
            <th className="text-white border-2 border-red-600">DATE</th>
            <th className="text-white border-2 border-red-600">TIME</th>
            <th className="text-white border-2 border-red-600">LOCATION</th>
          </tr>
        </thead>
        <tbody>{games}</tbody>
      </table>
    );
  }

  const [openSeasonId, setOpenSeasonId] = useState(
    process.env.REACT_APP_CURRENT_SEASON as string
  );

  const onExpandableCLick = (id: string): void => {
    setOpenSeasonId(id);
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center">
        <Expandable
          id="23-24"
          openSeasonId={openSeasonId}
          onClick={onExpandableCLick}
        >
          {scheduleTable(2023)}
        </Expandable>
        <Expandable
          id="22-23"
          openSeasonId={openSeasonId}
          onClick={onExpandableCLick}
        >
          {scheduleTable(2022)}
        </Expandable>
      </div>
    </div>
  );
}
