import type { stat } from './statHelper';

const TableHelper = {
  sortRows: function sortRows(
    a: stat,
    b: stat,
    sortField: string,
    asc: boolean
  ): number {
    const keyType = sortField as keyof typeof a;
    if (typeof a[keyType] === 'string') {
      if (asc) {
        return (a[keyType] as string).localeCompare(b[keyType] as string);
      } else {
        return (b[keyType] as string).localeCompare(a[keyType] as string);
      }
    } else {
      //number
      if (asc) {
        return +a[keyType] - +b[keyType];
      } else {
        return +b[keyType] - +a[keyType];
      }
    }
  }
};

export default TableHelper;
