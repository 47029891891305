import React, { useState, useEffect } from 'react';

type ExpandableProps = {
  id: string;
  openSeasonId: string;
  onClick: (id: string) => void;
  stats?: boolean; //very shitty code but when you start with shit you continue with it. some css with the stats table is absolutely fucked, therefore we need this
  children?: React.JSX.Element;
};

export default function Expandable(props: ExpandableProps): React.JSX.Element {
  const [open, setOpen] = useState(props.id === props.openSeasonId);

  useEffect(() => {
    setOpen(props.id === props.openSeasonId);
  }, [props.id, props.openSeasonId]);

  const handleClick = (): void => {
    setOpen(!open);
    props.onClick(props.id);
  };

  return (
    <div className="flex flex-col items-center w-full mt-2">
      <div
        className="flex justify-between w-full border-black border-2 hover:border-2 hover:rounded-md hover:border-red-600 cursor-pointer"
        onClick={() => {
          handleClick();
        }}
      >
        <div className="w-1/6 flex m-2">
          {open ? (
            <svg height="40" width="30">
              <polygon points="0,0 15,20 30,0" style={{ fill: 'red' }} />
            </svg>
          ) : (
            <svg height="40" width="30">
              <polygon
                points="0,0 20,15 0,30"
                style={{ fill: 'rgb(153 27 27)' }}
              />
            </svg>
          )}
        </div>
        <div className="w-1/6 flex justify-center">
          <b className="text-white text-center text-2xl">{props.id} SEASON</b>
        </div>
        <div className="w-1/6 flex justify-center"></div>
      </div>

      <div className={`flex-col w-full m-2 ${open ? 'block' : 'hidden'}`}>
        <p className="text-white text-center text-lg">
          You can scroll/swipe right on the table on mobile!
        </p>
        <div
          className={`${
            //sorry for this code:stats page has different css for the table, because stats is a very wide table
            props.stats ? 'xl:flex xl:justify-center' : 'flex justify-center'
          } overflow-x-auto`}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}
