import React, { useState } from 'react';
import Header from '../components/Header';
import results_2022_2023 from '../data/results/2022-2023/results.json';
import results_2023_2024 from '../data/results/2023-2024/results.json';
import { useNavigate } from 'react-router-dom';
import Expandable from '../components/Expandable';

export default function Results(): React.JSX.Element {
  const navigate = useNavigate();

  function resultsTable(firstYear: number): React.JSX.Element {
    const games: React.JSX.Element[] = [];
    //damn hardcoded shitty code
    let results = results_2022_2023;
    let season = '2022-2023';
    if (firstYear === 2022) {
      results = results_2022_2023;
      season = '2022-2023';
    } else if (firstYear === 2023) {
      results = results_2023_2024;
      season = '2023-2024';
    } else {
      //todo years in the future
    }
    //no games have been played so far
    if (results.length === 0) {
      results = [
        {
          opponent: 'Ourselves',
          result: 'W',
          date: new Date().toLocaleString('de-AT').split(',')[0],
          home: true,
          score: 'No games have been played yet!'
        }
      ];
    }
    results
      .sort((a, b) => {
        //from internet copied sort code, im so lazy i dont even write it
        // https://thewebdev.info/2022/01/23/how-to-sort-a-date-string-array-with-javascript/
        //dates need to be like this: 13.10.2022
        //newest should come on top
        const newA = a.date.split('.').reverse().join('-');
        const newB = b.date.split('.').reverse().join('-');
        return +new Date(newB) - +new Date(newA);
      })
      .forEach((game) => {
        const court = game.home ? 'HOME' : 'AWAY';
        const buttonColor =
          game.result === 'W'
            ? 'bg-gradient-to-r from-green-500 via-green-600 to-green-700'
            : 'bg-gradient-to-r from-red-500 via-red-600 to-red-700';
        const dateSplit = game.date.split('.');
        const postName = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
        games.push(
          <tr className="border-red-900 border-4">
            <td className="text-white border-2 border-red-600 text-center">
              <p>{game.opponent}</p>
            </td>
            <td className="text-white border-2 border-red-600 text-center">
              <p>{court}</p>
            </td>
            <td className="text-white border-2 border-red-600 text-center">
              {game.date}
            </td>
            <td className="text-white border-2 border-red-600 text-center">
              <button
                onClick={() => {
                  navigate('/post/' + season + '/' + postName);
                }}
                className={`${buttonColor} text-white font-semibold py-2 w-16 rounded-full shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out`}
              >
                {game.result}
              </button>
            </td>
            <td className="text-white border-2 border-red-600 text-center">
              {game.score}
            </td>
          </tr>
        );
      });

    return (
      <table className="w-2/4">
        <thead>
          <tr className="border-red-900 border-4">
            <th className="text-white border-2 border-red-600">OPPONENT</th>
            <th className="text-white border-2 border-red-600">COURT</th>
            <th className="text-white border-2 border-red-600">DATE</th>
            <th className="text-white border-2 border-red-600">RESULT</th>
            <th className="text-white border-2 border-red-600">SCORE</th>
          </tr>
        </thead>
        <tbody>{games}</tbody>
      </table>
    );
  }

  const [openSeasonId, setOpenSeasonId] = useState(
    process.env.REACT_APP_CURRENT_SEASON as string
  );

  const onExpandableCLick = (id: string): void => {
    setOpenSeasonId(id);
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center">
        <Expandable
          id="23-24"
          openSeasonId={openSeasonId}
          onClick={onExpandableCLick}
        >
          {resultsTable(2023)}
        </Expandable>
        <Expandable
          id="22-23"
          openSeasonId={openSeasonId}
          onClick={onExpandableCLick}
        >
          {resultsTable(2022)}
        </Expandable>
      </div>
    </div>
  );
}
