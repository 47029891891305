import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import roster from '../data/roster/roster.json';
import { StatHelper } from '../services/statHelper';

export default function Roster(): React.JSX.Element {
  //we dont use the 1000 value from env, because this should be only on the smallest screens
  //const VIEW_PORT_CUT_OFF = +(process.env.REACT_APP_VIEW_PORT_CUTOFF as string);
  //todo see what is phone common width
  const mobile = window.innerWidth < 550;

  const averages = StatHelper.calculateCareerAverages();

  type player = {
    name: string;
    jerseyNumber: number;
    position: string;
  };
  //require everything on load so that there are no stutters when clicking around
  const playerCardImages: Map<number, any> = new Map<number, any>();
  roster.forEach((player) => {
    playerCardImages.set(
      player.jerseyNumber,
      require('../images/playerCards/' + player.jerseyNumber + '.png')
    );
  });
  const [playerClicked, setPlayerClicked] = useState(false);
  const [frontPlayer, setFrontPlayer] = useState<player>();
  const [backPlayer, setBackPlayer] = useState<player>();

  const [onLastElement, setOnLastElement] = useState(false);
  const [onFirstElement, setOnFirstElement] = useState(false);

  useEffect(() => {
    if (backPlayer !== undefined) {
      if (sortedRoster.indexOf(backPlayer) === 0) {
        setOnFirstElement(true);
        setOnLastElement(false);
      } else if (sortedRoster.indexOf(backPlayer) === sortedRoster.length - 1) {
        setOnFirstElement(false);
        setOnLastElement(true);
      } else {
        setOnFirstElement(false);
        setOnLastElement(false);
      }
    }
  }, [backPlayer]);

  const [backCard, setBackCard] = useState(
    require('../images/playerCards/playerCard.png')
  );
  const [frontCard, setFrontCard] = useState(
    require('../images/playerCards/playerCardBack.png')
  );

  const sortedRoster = roster.sort((a, b) => {
    return a.jerseyNumber - b.jerseyNumber;
  });

  //this is to load all the images on startup hidden, so that the animation of the player card is smooth
  function hiddenImages(): React.JSX.Element[] {
    const hiddenImages: React.JSX.Element[] = [];
    playerCardImages.forEach((img) => {
      hiddenImages.push(
        //whatever key i dont care
        <div key={Math.random()}>
          <img src={img} />
        </div>
      );
    });
    return hiddenImages;
  }

  function playersTable(): React.JSX.Element {
    const players: React.JSX.Element[] = [];
    sortedRoster.forEach((player) => {
      players.push(
        <tr className="border-red-900 border-4" key={player.name}>
          <td className="text-white border-2 border-red-600 text-center">
            {player.name}
          </td>
          <td className="text-white border-2 border-red-600 text-center">
            {player.jerseyNumber}
          </td>
          <td className="text-white border-2 border-red-600 text-center">
            {player.position}
          </td>
          <td className="text-white border-2 border-red-600 text-center">
            <button
              className="bg-gradient-to-r from-red-500 via-red-600 to-red-700 flex justify-center py-1 w-12 rounded-full shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300 ease-in-out"
              onClick={() => {
                if (playerClicked) {
                  setFrontCard(playerCardImages.get(player.jerseyNumber));
                  setFrontPlayer(player);
                } else {
                  setBackCard(playerCardImages.get(player.jerseyNumber));
                  setBackPlayer(player);
                }
                setPlayerClicked(!playerClicked);
              }}
            >
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 20C7 17.2386 9.23858 15 12 15C14.7614 15 17 17.2386 17 20M7.2 20H16.8C17.9201 20 18.4802 20 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V7.2C20 6.0799 20 5.51984 19.782 5.09202C19.5903 4.71569 19.2843 4.40973 18.908 4.21799C18.4802 4 17.9201 4 16.8 4H7.2C6.0799 4 5.51984 4 5.09202 4.21799C4.71569 4.40973 4.40973 4.71569 4.21799 5.09202C4 5.51984 4 6.07989 4 7.2V16.8C4 17.9201 4 18.4802 4.21799 18.908C4.40973 19.2843 4.71569 19.5903 5.09202 19.782C5.51984 20 6.07989 20 7.2 20ZM14 10C14 11.1046 13.1046 12 12 12C10.8954 12 10 11.1046 10 10C10 8.89543 10.8954 8 12 8C13.1046 8 14 8.89543 14 10Z"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </td>
        </tr>
      );
    });

    let hidden = '';
    if (mobile) {
      hidden = playerClicked ? 'hidden' : '';
    }

    return (
      <table className={`${hidden}`}>
        <thead>
          <tr className="border-red-900 border-4">
            <th className="text-white border-2 border-red-600">NAME</th>
            <th className="text-white border-2 border-red-600">
              JERSEY NUMBER
            </th>
            <th className="text-white border-2 border-red-600">POSITION</th>
            <th className="text-white border-2 border-red-600">STATS</th>
          </tr>
        </thead>
        <tbody>{players}</tbody>
      </table>
    );
  }

  function playerStats(front: boolean): React.JSX.Element {
    const player = front ? frontPlayer : backPlayer;

    let statsBackground = '';
    if (player?.jerseyNumber && player.jerseyNumber % 3 === 0) {
      statsBackground = 'cardBackground';
    } else if (player?.jerseyNumber && player.jerseyNumber % 3 === 1) {
      statsBackground = 'cardBackground1';
    } else {
      statsBackground = 'cardBackground2';
    }
    return (
      <div
        style={{ backgroundImage: `url(/${statsBackground}.png)` }}
        className={`
        h-[210px] bg-black flex flex-col text-center text-white w-full items-center bg-cover rounded-b-xl border-t-8 border-t-red-950`}
      >
        <p className="font-[york] text-4xl font-bold">
          {player !== undefined ? player.name : 'TEAM'}
        </p>
        <div
          className={`
          ${
            averages.find((stat) => stat.no === player?.jerseyNumber) !==
            undefined
              ? 'hidden'
              : ''
          }
           w-full`}
        ></div>
        <div
          className={`${
            averages.find((stat) => stat.no === player?.jerseyNumber)
              ?.gamesPlayed === undefined
              ? 'hidden'
              : ''
          } flex flex-col w-full px-2`}
        >
          <p className="text-xl font-bold">
            {player?.jerseyNumber === undefined ? 'All Time' : 'Career'} Stats
          </p>
          <table className="w-full">
            <tbody>
              <tr className="border-red-900 border-4" key={0}>
                <td className="text-white border-2 border-red-600 text-center">
                  <b>GP:</b>{' '}
                  {
                    averages.find((stat) => stat.no === player?.jerseyNumber)
                      ?.gamesPlayed
                  }
                </td>
                <td className="text-white border-2 border-red-600 text-center">
                  <b>EFF:</b>{' '}
                  {averages
                    .find((stat) => stat.no === player?.jerseyNumber)
                    ?.eff.toFixed(2)
                    .replace(/[.]00$/, '')}
                </td>
                <td className="text-white border-2 border-red-600 text-center">
                  <b>PTS:</b>{' '}
                  {averages
                    .find((stat) => stat.no === player?.jerseyNumber)
                    ?.points.toFixed(2)
                    .replace(/[.]00$/, '')}
                </td>
              </tr>
              <tr className="border-red-900 border-4" key={1}>
                <td className="text-white border-2 border-red-600 text-center">
                  <b>AST:</b>{' '}
                  {averages
                    .find((stat) => stat.no === player?.jerseyNumber)
                    ?.assist.toFixed(2)
                    .replace(/[.]00$/, '')}
                </td>
                <td className="text-white border-2 border-red-600 text-center">
                  <b>RBD:</b>{' '}
                  {averages
                    .find((stat) => stat.no === player?.jerseyNumber)
                    ?.reboundTot.toFixed(2)
                    .replace(/[.]00$/, '')}
                </td>
                <td className="text-white border-2 border-red-600 text-center">
                  <b>BLK:</b>{' '}
                  {averages
                    .find((stat) => stat.no === player?.jerseyNumber)
                    ?.block.toFixed(2)
                    .replace(/[.]00$/, '')}
                </td>
              </tr>
              <tr className="border-red-900 border-4" key={2}>
                <td className="text-white border-2 border-red-600 text-center">
                  <b>STL:</b>{' '}
                  {averages
                    .find((stat) => stat.no === player?.jerseyNumber)
                    ?.steal.toFixed(2)
                    .replace(/[.]00$/, '')}
                </td>
                <td className="text-white border-2 border-red-600 text-center">
                  <b>TO:</b>{' '}
                  {averages
                    .find((stat) => stat.no === player?.jerseyNumber)
                    ?.turnover.toFixed(2)
                    .replace(/[.]00$/, '')}
                </td>
                <td className="text-white border-2 border-red-600 text-center">
                  <b>FG%:</b>{' '}
                  {averages
                    .find((stat) => stat.no === player?.jerseyNumber)
                    ?.fg.toFixed(2)
                    .replace(/[.]00$/, '')}
                </td>
              </tr>
              <tr className="border-red-900 border-4" key={2}>
                <td className="text-white border-2 border-red-600 text-center">
                  <b>2FG%:</b>{' '}
                  {averages
                    .find((stat) => stat.no === player?.jerseyNumber)
                    ?.fg2pt.toFixed(2)
                    .replace(/[.]00$/, '')}
                </td>
                <td className="text-white border-2 border-red-600 text-center">
                  <b>3FG%:</b>{' '}
                  {averages
                    .find((stat) => stat.no === player?.jerseyNumber)
                    ?.fg3pt.toFixed(2)
                    .replace(/[.]00$/, '')}
                </td>
                <td className="text-white border-2 border-red-600 text-center">
                  <b>FT%:</b>{' '}
                  {averages
                    .find((stat) => stat.no === player?.jerseyNumber)
                    ?.ft.toFixed(2)
                    .replace(/[.]00$/, '')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function playerCard(): React.JSX.Element {
    return (
      <div className="flex flex-col flex-grow items-center w-[320px]">
        <div className="group h-96 w-80 [perspective:1000px]">
          <div
            className={`relative h-full w-full rounded-t-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] ${
              playerClicked ? '[transform:rotateY(180deg)]' : ''
            }`}
          >
            <div className="absolute inset-0">
              <img
                className="h-full w-full rounded-t-xl object-cover shadow-xl shadow-black/40"
                src={frontCard}
                alt="playerCard"
              />
              {playerStats(true)}
            </div>
            <div className="absolute inset-0 h-full w-full rounded-t-xl bg-black/80 [transform:rotateY(180deg)] [backface-visibility:hidden]">
              <img
                className="h-full w-full rounded-t-xl object-cover shadow-xl shadow-black/40"
                src={backCard}
                alt="playerCardBack"
              />
              {playerStats(false)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function playerCardMobile(): React.JSX.Element {
    return (
      <div className="flex flex-col flex-grow items-center w-[320px]">
        <div className="group h-96 w-80 [perspective:1000px]">
          <div
            className={`relative h-full w-full rounded-t-xl shadow-xl transition-all`}
          >
            <div className="absolute inset-0 h-full w-full rounded-t-xl bg-black/80">
              <img
                className="h-full w-full rounded-t-xl object-cover shadow-xl shadow-black/40"
                src={backCard}
                alt="playerCardBack"
              />
              {playerStats(false)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function changePlayerButton(next: boolean): React.JSX.Element {
    return (
      <button
        className={`${
          (next && onLastElement) || (!next && onFirstElement) ? 'hidden' : ''
        } h-[562px] text-white text-xl flex flex-row justify-center items-center flex-grow`}
        onClick={() => {
          if (backPlayer !== undefined) {
            const nextPlayer = next
              ? sortedRoster[sortedRoster.indexOf(backPlayer) + 1]
              : sortedRoster[sortedRoster.indexOf(backPlayer) - 1];

            setBackCard(playerCardImages.get(nextPlayer.jerseyNumber));
            setBackPlayer(nextPlayer);
          }
        }}
      >
        {next ? <>&gt;</> : <>&lt;</>}
      </button>
    );
  }

  return (
    <div className="h-screen flex flex-col">
      <div className="hidden">{hiddenImages()}</div>
      <Header />
      <div className="flex flex-row justify-evenly flex-grow mt-2">
        <div className="flex flex-col ">{playersTable()}</div>
        {mobile ? (
          <dialog
            className={`${
              !playerClicked ? 'hidden' : ''
            } w-full h-full flex flex-row bg-black`}
          >
            <button
              onClick={() => {
                setPlayerClicked(false);
              }}
              className="absolute right-0 p-1 m-1 text-red-800 font-extrabold text-2xl border-2 border-red-800 rounded-xl z-10"
            >
              X
            </button>
            {changePlayerButton(false)}
            {playerCardMobile()}
            {changePlayerButton(true)}
          </dialog>
        ) : (
          <div className="flex flex-col">{playerCard()}</div>
        )}
      </div>
    </div>
  );
}
