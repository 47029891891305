import postList from '../posts/postList.json';

export type stat = {
  name: string;
  no: number;
  fgMade2pt: number;
  fgAtt2pt: number;
  fg2pt: number;
  fgMade3pt: number;
  fgAtt3pt: number;
  fg3pt: number;
  fgMade: number;
  fgAtt: number;
  fg: number;
  ftMade: number;
  ftAtt: number;
  ft: number;
  points: number;
  offRebound: number;
  defRebound: number;
  reboundTot: number;
  assist: number;
  turnover: number;
  block: number;
  steal: number;
  personalFoul: number;
  eff: number;
  gamesPlayed: number;
};

export const StatHelper = {
  calculateCareerAverages: function calculateCareerAverages(): stat[] {
    const seasons: string[] = [];
    for (const index in postList.posts) {
      seasons.push(index);
    }
    return this.calculateSeasonAverages(seasons);
  },
  calculateSeasonAverages: function calculateAverages(
    seasons: string[]
  ): stat[] {
    const boxScores: stat[][] = [];

    type postListType = typeof postList.posts; //wtf typescript is a pain in my ass
    seasons.forEach((season) => {
      postList.posts[season as keyof postListType].forEach((postName) => {
        const post = require('../posts/' + season + '/' + postName + '.json');
        if (post.match) {
          const boxScore = require('../data/boxScores/' + postName + '.json');
          boxScores.push(boxScore);
        }
      });
    });

    const averages: stat[] = [];
    const sums: stat[] = [];

    boxScores.forEach((boxScore) => {
      boxScore.forEach((player) => {
        const foundPlayer = sums.find(
          (playerSum) => playerSum.name === player.name
        );
        if (foundPlayer === undefined) {
          player.gamesPlayed = 1;
          sums.push(player);
        } else {
          const foundPlayerIndex = sums.findIndex(
            (playerSum) => playerSum.name === player.name
          );
          const sum: stat = {
            name: foundPlayer.name,
            no: foundPlayer.no,
            fgMade2pt: foundPlayer.fgMade2pt + player.fgMade2pt,
            fgAtt2pt: foundPlayer.fgAtt2pt + player.fgAtt2pt,
            fg2pt: foundPlayer.fg2pt + player.fg2pt,
            fgMade3pt: foundPlayer.fgMade3pt + player.fgMade3pt,
            fgAtt3pt: foundPlayer.fgAtt3pt + player.fgAtt3pt,
            fg3pt: foundPlayer.fg3pt + player.fg3pt,
            fgMade: foundPlayer.fgMade + player.fgMade,
            fgAtt: foundPlayer.fgAtt + player.fgAtt,
            fg: foundPlayer.fg + player.fg,
            ftMade: foundPlayer.ftMade + player.ftMade,
            ftAtt: foundPlayer.ftAtt + player.ftAtt,
            ft: foundPlayer.ft + player.ft,
            points: foundPlayer.points + player.points,
            offRebound: foundPlayer.offRebound + player.offRebound,
            defRebound: foundPlayer.defRebound + player.defRebound,
            reboundTot: foundPlayer.reboundTot + player.reboundTot,
            assist: foundPlayer.assist + player.assist,
            turnover: foundPlayer.turnover + player.turnover,
            block: foundPlayer.block + player.block,
            steal: foundPlayer.steal + player.steal,
            personalFoul: foundPlayer.personalFoul + player.personalFoul,
            eff: foundPlayer.eff + player.eff,
            gamesPlayed: foundPlayer.gamesPlayed + 1
          };
          sums[foundPlayerIndex] = sum;
        }
      });
    });

    sums.forEach((player) => {
      const gameCount = player.gamesPlayed;
      const avg: stat = {
        name: player.name,
        no: player.no,
        fgMade2pt: player.fgMade2pt / gameCount,
        fgAtt2pt: player.fgAtt2pt / gameCount,
        fg2pt:
          player.fgAtt2pt === 0
            ? 0
            : (player.fgMade2pt / player.fgAtt2pt) * 100,
        fgMade3pt: player.fgMade3pt / gameCount,
        fgAtt3pt: player.fgAtt3pt / gameCount,
        fg3pt:
          player.fgAtt3pt === 0
            ? 0
            : (player.fgMade3pt / player.fgAtt3pt) * 100,
        fgMade: player.fgMade / gameCount,
        fgAtt: player.fgAtt / gameCount,
        fg: player.fgAtt === 0 ? 0 : (player.fgMade / player.fgAtt) * 100,
        ftMade: player.ftMade / gameCount,
        ftAtt: player.ftAtt / gameCount,
        ft: player.ftAtt === 0 ? 0 : (player.ftMade / player.ftAtt) * 100,
        points: player.points / gameCount,
        offRebound: player.offRebound / gameCount,
        defRebound: player.defRebound / gameCount,
        reboundTot: player.reboundTot / gameCount,
        assist: player.assist / gameCount,
        turnover: player.turnover / gameCount,
        block: player.block / gameCount,
        steal: player.steal / gameCount,
        personalFoul: player.personalFoul / gameCount,
        eff: player.eff / gameCount,
        gamesPlayed: player.gamesPlayed
      };
      averages.push(avg);
    });
    return averages;
  }
};
