import React, { useState } from 'react';
import Header from '../components/Header';
import Expandable from '../components/Expandable';
import season2122 from '../data/stats2021_2022/season21-22.json';
import TableHelper from '../services/tableHelper';
import { StatHelper } from '../services/statHelper';

export default function Stats(): React.JSX.Element {
  let asc = true;

  const [table_2022_2023, set_table_2022_2023] = useState(
    getStatsTable('name', 2022)
  );

  const [table_2023_2024, set_table_2023_2024] = useState(
    getStatsTable('name', 2023)
  );

  //next season, i will need to fix this and make it modularized but why do tomorrow's work today?
  //now its next season and i am extending shitty code with more shitty code
  //a very ugly table :(
  function getStatsTable(
    sortField: string,
    firstYear: number
  ): React.JSX.Element {
    let season = '2022-2023';
    if (firstYear === 2023) {
      season = '2023-2024';
    }
    const setTable = (table: React.JSX.Element): void => {
      if (firstYear === 2022) {
        set_table_2022_2023(table);
      } else if (firstYear === 2023) {
        set_table_2023_2024(table);
      } else {
        //todo new seasons
      }
    };

    const averages = StatHelper.calculateSeasonAverages([season]);
    //todo delete
    StatHelper.calculateCareerAverages();

    const players: React.JSX.Element[] = [];
    averages
      .sort(function (a, b) {
        //this first if else puts Team in the last row
        if (a.name === 'Team') {
          return 1;
        } else if (b.name === 'Team') {
          return -1;
        }

        return TableHelper.sortRows(a, b, sortField, asc);
      })
      .forEach((player) => {
        const textClass =
          player.name === 'Team'
            ? 'bg-white font-bold text-center border-2 border-black'
            : 'text-white border-2 border-red-600 text-center';
        players.push(
          <tr key={player.name} className="border-red-900 border-t-2">
            <td
              className={textClass + ' absolute w-36 -ml-36 -mt-0.5 bg-black'}
            >
              {player.name}
            </td>
            <td className={textClass}>{player.no}</td>
            <td className={textClass}>{player.gamesPlayed}</td>
            <td className={textClass}>{player.fgMade2pt.toFixed(2)}</td>
            <td className={textClass}>{player.fgAtt2pt.toFixed(2)}</td>
            <td className={textClass}>{player.fg2pt.toFixed(2)}</td>
            <td className={textClass}>{player.fgMade3pt.toFixed(2)}</td>
            <td className={textClass}>{player.fgAtt3pt.toFixed(2)}</td>
            <td className={textClass}>{player.fg3pt.toFixed(2)}</td>
            <td className={textClass}>{player.fgMade.toFixed(2)}</td>
            <td className={textClass}>{player.fgAtt.toFixed(2)}</td>
            <td className={textClass}>{player.fg.toFixed(2)}</td>
            <td className={textClass}>{player.ftMade.toFixed(2)}</td>
            <td className={textClass}>{player.ftAtt.toFixed(2)}</td>
            <td className={textClass}>{player.ft.toFixed(2)}</td>
            <td className={textClass}>{player.offRebound.toFixed(2)}</td>
            <td className={textClass}>{player.defRebound.toFixed(2)}</td>
            <td className={textClass}>{player.reboundTot.toFixed(2)}</td>
            <td className={textClass}>{player.assist.toFixed(2)}</td>
            <td className={textClass}>{player.turnover.toFixed(2)}</td>
            <td className={textClass}>{player.block.toFixed(2)}</td>
            <td className={textClass}>{player.steal.toFixed(2)}</td>
            <td className={textClass}>{player.personalFoul.toFixed(2)}</td>
            <td className={textClass}>{player.points.toFixed(2)}</td>
            <td className={textClass}>{player.eff.toFixed(2)}</td>
          </tr>
        );
      });
    const thClassName = 'cursor-pointer text-white border-2 border-red-600';

    asc = !asc;

    return (
      <table className="ml-36">
        <thead>
          <tr>
            {/*use this character if you want to have non line breaking space: &nbsp;   */}
            <th
              onClick={() => {
                setTable(getStatsTable('name', firstYear));
              }}
              className={thClassName + ' absolute w-36 -ml-36 -mt-0.5 bg-black'}
            >
              Player
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('no', firstYear));
              }}
              className={thClassName}
            >
              Jersey&nbsp;No
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('gamesPlayed', firstYear));
              }}
              className={thClassName}
            >
              Games&nbsp;Played
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('fgMade2pt', firstYear));
              }}
              className={thClassName}
            >
              2FGM
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('fgAtt2pt', firstYear));
              }}
              className={thClassName}
            >
              2FGA
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('fg2pt', firstYear));
              }}
              className={thClassName}
            >
              2FG%
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('fgMade3pt', firstYear));
              }}
              className={thClassName}
            >
              3FGM
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('fgAtt3pt', firstYear));
              }}
              className={thClassName}
            >
              3FGA
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('fg3pt', firstYear));
              }}
              className={thClassName}
            >
              3FG%
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('fgMade', firstYear));
              }}
              className={thClassName}
            >
              FGM
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('fgAtt', firstYear));
              }}
              className={thClassName}
            >
              FGA
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('fg', firstYear));
              }}
              className={thClassName}
            >
              FG%
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('ftMade', firstYear));
              }}
              className={thClassName}
            >
              FTM
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('ftAtt', firstYear));
              }}
              className={thClassName}
            >
              FTA
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('ft', firstYear));
              }}
              className={thClassName}
            >
              FT%
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('offRebound', firstYear));
              }}
              className={thClassName}
            >
              OREB
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('defRebound', firstYear));
              }}
              className={thClassName}
            >
              DREB
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('reboundTot', firstYear));
              }}
              className={thClassName}
            >
              REB
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('assist', firstYear));
              }}
              className={thClassName}
            >
              AST
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('turnover', firstYear));
              }}
              className={thClassName}
            >
              TO
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('block', firstYear));
              }}
              className={thClassName}
            >
              BLK
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('steal', firstYear));
              }}
              className={thClassName}
            >
              STL
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('personalFoul', firstYear));
              }}
              className={thClassName}
            >
              PF
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('points', firstYear));
              }}
              className={thClassName}
            >
              PTS
            </th>
            <th
              onClick={() => {
                setTable(getStatsTable('eff', firstYear));
              }}
              className={thClassName}
            >
              EFF
            </th>
          </tr>
        </thead>
        <tbody>{players}</tbody>
      </table>
    );
  }

  function table_2021_2022(): React.JSX.Element {
    const players: JSX.Element[] = [];
    season2122.forEach((player) => {
      const textClass =
        player.player === 'Team'
          ? 'bg-white font-bold text-center border-2 border-black'
          : 'text-white border-2 border-red-600 text-center';

      players.push(
        <tr key={player.player} className="border-red-900 border-4">
          <td className={textClass}>{player.player}</td>
          <td className={textClass}>{player.wat3}</td>
          <td className={textClass}>{player.phalanx}</td>
          <td className={textClass}>{player.klosterneuburg}</td>
          <td className={textClass}>{player.wat22}</td>
          <td className={textClass}>{player.flames6}</td>
          <td className={textClass}>{player.basketfighters}</td>
          <td className={textClass}>{player.flames4}</td>
          <td className={textClass}>{player.average}</td>
          <td className={textClass}>{player.pointsTotal}</td>
          <td className={textClass}>{player.gamesPlayed}</td>
        </tr>
      );
    });

    const thClassName = 'text-white border-2 border-red-600';
    return (
      <table>
        <thead>
          <tr className="border-red-900 border-4">
            <th className={thClassName}>PLAYER</th>
            <th className={thClassName}>WAT&nbsp;3</th>
            <th className={thClassName}>PHALANX</th>
            <th className={thClassName}>KLOSTERNEUBURG</th>
            <th className={thClassName}>WAT&nbsp;22</th>
            <th className={thClassName}>FLAMES&nbsp;6</th>
            <th className={thClassName}>EUROFIGHTERS</th>
            <th className={thClassName}>FLAMES&nbsp;4</th>
            <th className={thClassName}>AVERAGE</th>
            <th className={thClassName}>TOTAL&nbsp;POINTS</th>
            <th className={thClassName}>GAMES&nbsp;PLAYED</th>
          </tr>
        </thead>
        <tbody>{players}</tbody>
      </table>
    );
  }

  const [openSeasonId, setOpenSeasonId] = useState(
    process.env.REACT_APP_CURRENT_SEASON as string
  );

  const onExpandableCLick = (id: string): void => {
    setOpenSeasonId(id);
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center">
        <Expandable
          id="23-24"
          openSeasonId={openSeasonId}
          onClick={onExpandableCLick}
          stats={true}
        >
          {table_2023_2024}
        </Expandable>
        <Expandable
          id="22-23"
          openSeasonId={openSeasonId}
          onClick={onExpandableCLick}
          stats={true}
        >
          {table_2022_2023}
        </Expandable>
        <Expandable
          id="21-22"
          openSeasonId={openSeasonId}
          onClick={onExpandableCLick}
          stats={true}
        >
          {table_2021_2022()}
        </Expandable>
      </div>
    </div>
  );
}
